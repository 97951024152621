import moment from 'moment'
import React, { Component } from 'react'
import { getCuramLife, gethealthcard } from '../ApiCall'
import './WebScreens.css'
export default class CuramLifeCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subscribed: false,
      memberDetails: []
    }
  }

  componentDidMount() {
    if (localStorage.getItem('memberSSN') !== '') {
      // this.getCuramLifeCard({
      //   memberSSN: localStorage.getItem('memberSSN'),
      //   type: 'self'
      // })
      this.getCuramLifeCard({
        memberSSN: localStorage.getItem('memberSSN'),
        type: 'family'
      })
    } else {
      gethealthcard().then(res => {
        if (res) {
          // this.getCuramLifeCard({
          //   memberSSN: localStorage.getItem('memberSSN'),
          //   type: 'self'
          // })
          this.getCuramLifeCard({
            memberSSN: localStorage.getItem('memberSSN'),
            type: 'family'
          })
        }
      })
    }
  }

  getCuramLifeCard = obj => {
    getCuramLife(obj).then(res => {
      console.log(res)
      if(res.data.message && res.data.message=="Invalid Member SSN"){
        this.setState({subscribed: false})
      }else
      this.setState({ memberDetails: [...this.state.memberDetails, ...res?.data], subscribed: res.data?.length > 0 && res.data.filter(x=>x.status=="Active - Billable").length>0 ? true : false })
    })
  }

  render() {
    return (
      <div class="contactinfocard">
        <div class="myneeds_top_container">
          <img src={require('../Images/CuramLife.webp')} class="CuramLife" />
          <div class="myneeds_header_text" style={{ margin: 0 }}>
            UHF / Curam Wellness Center™
          </div>
        </div>

        <div class="">
          <div class="contact_infomiddiv text-left" style={{ paddingLeft: 13, paddingRight: 24, paddingBottom: 6 }}>
            {!this.state.subscribed && (
              <div>
                <div class="The-UHF-Curam-Wellness-Center-1" style={{ paddingTop: 16, paddingBottom: 6 }}>
                The UHF / Curam Wellness Center can support UHF members and their families with easy access to: nutritional and dietary programs; financial assistance application support; hospital fee estimate support; <span class="text-style-2" style={{cursor: 'pointer'}} onClick={()=>window.open("https://www.universalhealthfellowship.org/curamlife-care-coordination-app/")}>an online app</span> that helps you customize the best caregiver support for you and your family, a digital health assistant app that can help you access your essential health data, and access to reduced-rate virtual physical therapy sessions. All UHF / Curam Wellness Center services are available to UHF members at no additional cost to them. Read more about it <span class="text-style-2" style={{cursor: 'pointer'}} onClick={()=>window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Curam-Wellness-Center-Promo-Collateral.pdf")}>here</span>
                  <div class="text-style-1" style={{ marginTop: 15 }}>
                    Can we hook you up with the Wellness Center?
                  </div>
                  <ul style={{ paddingBottom: 6 }}>
                    <li style={{ marginLeft: -15, marginTop: 10 }}>
                      Call a CuramLife rep at&nbsp;
                      <span class="text-style-2">
                        <a style={{textDecoration:"none"}} href="tel:888-282-8726">
                          888-AT-CURAM</a> <span style={{ color:"black" }}>(888-282-8726 – Mon-Fri, 8:30am-5:00pm ET)</span>
                      </span>
                    </li>
                    <li style={{ marginLeft: -15, marginTop: 5 }}>
                      Email&nbsp;
                      <span class="text-style-2">
                        <a style={{textDecoration:"none"}} href="mailto:UHFmembers@curamlife.com">uhfmembers@curamlife.com</a>
                      </span>
                    </li>
                    <li style={{ marginLeft: -15, marginTop: 5 }}>
                      or Select a convenient time where we can call you&nbsp;
                      <span class="text-style-2">
                        <a style={{textDecoration:"none"}}
                          href={`https://www.universalhealthfellowship.org/enroll-curam-wellness-center/?mname=${encodeURI(
                            localStorage.getItem('subscriberName')
                          )}&memail=${localStorage.getItem('userMail')}`}
                          target="_blank"
                        >
                          here
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {this.state.subscribed && (
              <div>
                <div style={{ paddingTop: 16 }}>
                  <span class="Subscribed-Program">Subscribed Program</span>{' '}
                </div>
                <div>
                  <span class="Value">{`Kelly's Choice Wellness and Nutrition Program`}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 18 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                    <div style={{ width: '50%' }}>
                      <span class="Title">Subscribers</span>
                    </div>
                    <div>
                      <span class="Title">Effective Date</span>
                    </div>
                  </div>
                  {this.state.memberDetails.filter(x=>x.status=="Active - Billable").map(item => (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                          <div style={{ width: '50%' }}>
                            <span class="number1">{`${item.prefix || ""} ${item.firstName} ${item.middleName || ""} ${item.lastName} ${item.suffix || ""}`}</span>
                          </div>
                          {/* </div>
                      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}> */}
                          <div>
                            <span class="number1">{item.curamBenefitBeginDate?moment(item.curamBenefitBeginDate).format("MMM DD, YYYY") :"NA"}</span>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
