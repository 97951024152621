import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment'
import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import AccordionViewModal from './AccordionViewModal'
import './SimpleAccordion.css'
import axios from 'axios'
import CommonLoader from '../../CommonLoader'

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    padding: '0px',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#ffffff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 120,
    paddingLeft: '5px',
    paddingRight: '5px',
    '&$expanded': {
      minHeight: 125
    }
  },

  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails)
export default class ContributionAccordian extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: '',
      formatedData: props.data,
      showModal: false,
      modalData: null,
      expansionPanelOpen: false,
      loader: true,
      contributionData: []
    }
    console.log('Accordion pros', this.state.formatedData)

    console.log('=========props data isss==========', this.props)
  }

  componentDidMount () {
    this.getcontributionData()
  }

  getcontributionData () {
    let member_id = localStorage.getItem('Member_EMPID')
    let request = {
      username: '4aah9404bptfvbnnvj0jtn06jo',
      password: 'u4mlm6tmk15rv14e2pm0ivagv66ocm3nopphc56hb5p6vkl4tk1'
    }
    this.setState({loader: true})
    axios
      .post(
        'https://prodshareplus.auth.us-east-2.amazoncognito.com/token?grant_type=client_credentials&client_id=4aah9404bptfvbnnvj0jtn06jo&scope=apiauthidentifier/member.read',
        {},
        {
          auth: request,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }
      )
      .then(response => {
        axios
          .get(
            `https://2btt51qv59.execute-api.us-east-2.amazonaws.com/dev/subcriber/member-contributions?memberId=${member_id}&pageNo=0&numberOfRows=100`,
            {
              headers: {
                'x-api-key': 'y29gwmfNNj4ALv9jE5Hzk3V3CBUsMOnaaexsS4fO',
                token: response.data.access_token
              }
            }
          )
          .then(response => {
            let deencrpt = JSON.parse(atob(response.data))
            if (deencrpt) {
              console.log('deencrpt: ', deencrpt);
              if (deencrpt.data.length > 0) {
                this.setState({contributionData: deencrpt.data})
                console.log('deencrpt.data: ', deencrpt.data);
                    this.setState({loader: false})
              } else {
                    this.setState({loader: false})
              }
            } else {
                  this.setState({loader: false})
            }
          })
          .catch(err => {
                this.setState({loader: false})
          })
      })
      .catch(err => {
        this.setState({loader: false})
      })
  }

  handleChange = panel => (event, expanded) => {
    console.log('expanded====', panel, expanded)
    this.setState({ expanded: expanded ? panel : false })
  }

  openModalInfo(dataIndex) {
    this.setState({
      showModal: true,
      modalData: this.state.formatedData[dataIndex]
    })
  }

  closeModalInfo() {
    this.setState({
      showModal: false
    })
  }

  //--- To get updated props on loadMore Btn click
  componentWillReceiveProps(nextProps) {
    this.setState({ formatedData: nextProps.data })
  }

  getDate(date) {
    const isDateValid = moment(date)['_isValid']
    if (isDateValid) {
      return moment(date).format('MMMM D, YYYY')
    }
    return date
  }

  numberWithCommas(x) {
    if (x) {
      if (x.toString().startsWith('$')) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    } else {
      return 'NA'
    }
  }

  floatconversion = x => {
    if (x) {
      if (x.toString().startsWith('$')) {
        let substr = x.substr(1, x.length)
        return (
          '$' +
          parseFloat(substr)
            .toFixed(2)
            .toString()
        )
      } else {
        return x.toFixed(2)
      }
    } else {
      return undefined
    }
  }

  render() {
    return (
      <div>
        {this.state.loader ? <CommonLoader /> : null}
        {
          this.state.contributionData[0] ?
          this.state.contributionData.map((data, index) => (
            <Accordion expanded={this.state.expanded === 'panel' + index}>
              <AccordionSummary
          
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="panelSummary"
              >
                <Grid container>
                  {/* <Grid item xs={8} className="itemWrap">
                    <div className="Expense-Number221">Reference #</div>
                    <div className="Expense-Number211">{data.need_id ? data.need_id : 'NA'}</div>
                  </Grid> */}

                  <Grid item xs={6} className="itemWrap">
                    <div className="Expense-Number221">Date</div>
                    <div className="Expense-Number211">
                    {data.service_date ? this.getDate(data.service_date) : 'NA'}
                    </div>
                  </Grid>
  
                  <Grid item xs={12} className="itemWrap">
                    <div className="Expense-Number221">Contribution Amount</div>
                    {data.contribution_amount ? this.numberWithCommas(this.floatconversion(data.contribution_amount)) : 'NA'}
                  </Grid>
  
                  <Grid item xs={12} className="itemWrap">
                    <div className="Expense-Number221">Towards Health Need</div>
                    <div className="Expense-Number211">{data.icd_10_code_description ? data.icd_10_code_description : 'NA'}</div>
                  </Grid>
  
                  <Grid item xs={6} className="itemWrap">
                    <div className="Expense-Number221">Member</div>
                    <div className="Expense-Number211">{data.name_initials ? data.name_initials : 'NA'}</div>
                  </Grid>
                </Grid>
              </AccordionSummary>
  
            </Accordion>
          ))
          :
            !this.state.loader &&
          <div style={{ padding: '180px 0px', textAlign: 'center' }} className="mobile_data_not_found">
            There is no contribution data available at the moment. 
          </div>
        }
      </div>
    )
  }
}

const sampleData2 = [
  { id: 733, planId: '7006', idcardField: 'Telemedicine', fieldValue: '$0' },
  { id: 735, planId: '7006', idcardField: 'PCP Office Visit', fieldValue: '$35' },
  { id: 736, planId: '7006', idcardField: 'Urgent Care', fieldValue: '$100' },
  { id: 737, planId: '7006', idcardField: 'ER Visit', fieldValue: '$400' }
]

const myrowsjsondata = [
  {
    member_id: '600016',
    bill_key: '20116',
    first_name: 'CHARLES',
    last_name: 'CASH',
    date_paid: 'None',
    date_received: '2020-04-20',
    status: 'IN REVIEW',
    charged_amount: 10.00,
    paid_amount: '0.00',
    paid_provider_name: 'Primary respiratory Tiberculosis'
  },
  {
    member_id: '600017',
    bill_key: '20467',
    first_name: 'AHARLES',
    last_name: 'BASH2',
    date_paid: '2020-05-20',
    date_received: '2020-04-21',
    status: 'FINAL',
    charged_amount: 100.00,
    paid_amount: '10.00',
    paid_provider_name: 'Theracic region'
  },
  {
    member_id: '600018',
    bill_key: '20118',
    first_name: 'MHARLES',
    last_name: 'KASH',
    date_paid: 'None',
    date_received: '2020-04-22',
    status: 'FINAL',
    charged_amount: 200.00,
    paid_amount: '20.00',
    paid_provider_name: 'Tracmatic spondylopathy'
  },
 
]