import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { Component } from 'react'
import { getCardDetails, getCardEnableData, getsharingguidlineslink, getWelcomeBooklet, getProgramInfo, getDocumentData } from '../ApiCall'
import CommonLoader from '../CommonLoader'
import ChatIcon from '../WebScreen/ChatBox/ChatIcon'
import MobCopyright from './MobCopyright'
import MobileFooter from './MobileFooter'
import apiservice from '../../utils/apiservice'
export default class DocumentsScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      enable: null,
      loader: false,
      providerLink: '',
      disableWelcome:false,
      documentData : []
    }
  }

  componentDidMount() {
    this.setState({ loader: true })

    this.getCardEnable()
    this.getDocumentList();
    getCardDetails().then(res => {
      if (res.data.response) {
        let documentCardData = res.data.response.find(obj => obj.cardtitle === 'Documents')
        this.setState({
          enable: documentCardData.enable,
        })
      }
    })
    getProgramInfo()
      .then(res => {
        if (res.data && res.data.programInfo) {
          var str = res.data.programInfo.programName
          if (str.includes('+')) {
            var str1 = res.data.programInfo.programName
            var res1 = str1.split(' ')
            this.setState({ programname: res1[0], programname2: ' with ' + res1[2], showwithacsm: true, loader: false })
          } else {
            this.setState({ programname: res.data.programInfo.programName, showwithacsm: false, loader: false })
          }
        } else {
          this.setState({ loader: false })
        }
      })
      .catch()
  }
  openBrochure = (planName) => {
    if (planName == 'UHS1' || planName == 'UHS2' || planName == 'UHS3' || planName == 'UHS4' || planName == 'UHS5' || planName == 'UHS6') {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf")
    } else if (planName == 'UHS SmartShare') {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-SmartShare.pdf")
    } else if (planName == 'THS50' || planName == 'THS75' || planName == 'THS100') {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Thrive-Program-Grid.pdf")
    } else if (planName == 'HL200' || planName == 'HL400' || planName == 'HL600') {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf")
    } else if (planName == 'HLC200' || planName == 'HLC400' || planName == 'HLC600') {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf")
    } else if (planName == 'ES1' || planName == 'ES2' || planName == 'ES3') {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS-EssentialShare-Program-Grid.pdf")
    } else if (planName == 'CHS200' || planName == 'CHS400' || planName == 'CHS600' ) {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Community-HealthShare-Program-Grid.pdf")
    } else if (planName == 'PRS50' || planName == 'PRS75' || planName == 'PRS100' ) {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Prosper-Program-Brochure-Grid.pdf")
    }
  }

  getCardEnable = () => {
    let client_id = localStorage.getItem('CLIENT_ID')

    this.setState({ loader: true })
    getCardEnableData(client_id, 'Documents').then(res => {
      console.log('getCardEnableData=====', res.data.response.enable)
      this.setState({ loader: false })
      if (res.data.response.enable == 'false' || res.data.response.enable == false) {
        window.location.href = '/'
      }
    })
  }
  
  getDocumentList =async ()=>{
    let plainId = localStorage.getItem('planId');
    let memberSSN = localStorage.getItem('memberSSN');
    let client_id = localStorage.getItem('CLIENT_ID');
    if(!memberSSN || !client_id){
      let mail = localStorage.getItem('userMail')
      var request = { email: mail }
      const res = await apiservice.post(process.env.REACT_APP_BASE_URL + 'v2/memberportal/idcard', request)
      memberSSN = res?.data?.memberIdCardList[0]?.memberSSN
      client_id = res?.data?.memberIdCardList[0]?.clientId
    }
    getDocumentData(plainId,memberSSN,client_id).then(res => {
      console.log('getCardEnableData=====', res.data.response)
      this.setState({documentData:res.data});
    })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  openwelcome = () => {
    // window.open("https://carynhealth-memberportal-dev-documents.s3.us-east-2.amazonaws.com/AFA/UHS+Welcome+Booklet+-+6kAFAUHSR0626E060920.pdf");

    getWelcomeBooklet().then(res => {
      console.log('Welcome booklet=======', res)
      if (res.data && res.data.length > 0) {
        console.log('Welcome booklet=======')
        let providerLink = res.data[0].fieldValue
        window.location.href = providerLink
        // saveAs(providerLink, "Welcome booklet.pdf");
      } else {
        console.log('Welcome booklet else block')
        // alert('Data Not Availabe')
      }
    })
  }

  opensecond() {
    window.open(
      'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS+Member+Responsibilities.pdf'
    )
  }

  openthird() {
    window.open(
      ' https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Statement-of-Shared-Faith-%26-Beliefs.pdf'
    )
  }

  getstartedHealth() {
    window.open(
      'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+How+do+I+Get+Started+with+Health+Sharing.pdf'
    )
  }

  open5question() {
    window.open(
      'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+5+Questions+about+Health+Sharing.pdf'
    )
  }

  tipsTelemedicin() {
    window.open('https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+Tips+on+Telemedicine.pdf')
  }

  great4reason() {
    window.open(
      'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+4+Great+Reasons+to+share+Universal+HealthShare+with+Your+Friends.pdf'
    )
  }

  quick3facts() {
    window.open(
      'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+Doctors!+3+Quick+Facts+about+Health+Sharing.pdf'
    )
  }
  PharmacyVaccine=()=>{
    window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Pharmacy-Vaccine-Reimbursement-Transmittal.pdf")
  }
  TransmittalForm=()=>{
    window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Submitted-Needs-Transmittal-Form.pdf")
  }

  opensharingguidlines = () => {
    getsharingguidlineslink().then(res => {
      console.log('Sharing Guidlines', res)
      if (res.data && res.data.length > 0) {
        console.log('Shating guidlines response')
        let providerLink = res.data[0].fieldValue
        window.location.href = providerLink
        //saveAs(providerLink, "Sharing Guidlines.pdf");
      } else {
        console.log('Sharing guidlines else block')
        // alert('Data Not Availabe')
      }
    })
  }

  render() {
    return (
      <div>
        {this.state.loader ? <CommonLoader /> : null}
        <div class="digitalcard_header">
          <ArrowBackIcon style={{ width: '24px', height: '24px', color: '#ffffff' }} onClick={() => this.goBack()} />
          <div class="digitalcard_headerdiv">Documents</div>
          {/* <NotificationsIcon style={{width:'24px',height:'24px',color:'rgba(255, 255, 255, 0.74)'}}/> */}
        </div>

        {this.state.enable ? (
          // <div className="doc_infomiddiv text-left" style={{overflowY:"auto",height:"70vh"}}>
          //   <div className={localStorage.getItem("disableWelcome")=="true"?"doc_welcome_textDisabled":"doc_welcome_text"} onClick={this.openwelcome}>
          //     Welcome Booklet
          //   </div>
          //   {(this.state.programname == 'HLC200' || this.state.programname == 'HLC400' || this.state.programname == 'HLC600') ?
          //       null :
          //       <div className="doc_welcome_text" onClick={() => this.openBrochure(this.state.programname)}>
          //         Brochure Grid
          //       </div>
          //     }
          //   <div className={localStorage.getItem("disableSharing")=="true"?"doc_welcome_textDisabled":"doc_welcome_text"}  onClick={this.opensharingguidlines}>
          //     Sharing Guidelines
          //   </div>
          //   <div className="doc_welcome_text" onClick={() => this.opensecond()}>
          //     Member Responsibilities
          //   </div>
          //   <div className="doc_welcome_text" onClick={() => this.openthird()}>
          //     Statement of Shared Faith and Beliefs
          //   </div>

          //   <div className="doc_welcome_text" onClick={() => this.getstartedHealth()}>
          //     Get Started with Health Sharing
          //   </div>
          //   <div className="doc_welcome_text" onClick={() => this.open5question()}>
          //     5 Questions about Health Sharing
          //   </div>
          //   {<div className="doc_welcome_text" onClick={() => this.tipsTelemedicin()}>
          //     Tips on Telemedicine
          //   </div>}
          //   <div className="doc_welcome_text" onClick={() => this.great4reason()}>
          //     4 Great Reasons to share UHS
          //   </div>
          //   <div className="doc_welcome_text" onClick={() => this.quick3facts()}>
          //     3 Quick Facts for Doctors
          //   </div>
          //   <div className="doc_welcome_text" onClick={() => this.TransmittalForm()}>
          //       Submitted Needs Transmittal Form
          //     </div>
          //     <div className="doc_welcome_text" onClick={() => this.PharmacyVaccine()}>
          //     Pharmacy Vaccine Reimbursement Transmittal
          //     </div>
          // </div>
          <div className="doc_infomiddiv text-left">
          {this.state.documentData?.map((value,index)=>
            <div className="doc_welcome_text" key={index} onClick={()=>window.open(value.document)}>
              {value.description}
            </div>
           )
          }
        </div>
        ) : this.state.enable !== null ? (
          <div style={{ padding: '35px', marginTop: '50%' }}>
            <div className="tempModalTxt">
              We’re facing some technical difficulties, due to which this feature is currently unavailable. For support, call Member
              Services at {localStorage.getItem('CONTACT_NUMBER')}, Monday through Friday, 8.00am to 8.00pm CST.
            </div>
          </div>
        ) : null}

            {/* <ChatIcon showAIChatIcon={true} />
          <ChatIcon />
          <MobCopyright  style={{display: 'relative', marginBottom: 60}} />
        <div class="fixed-bottom">
          <MobileFooter />
        </div> */}
          <div>
              {this.state.loader ?null: <div>
                <div style={{ bottom: '9vh', position: 'relative' }}>
                  <ChatIcon showAIChatIcon={true} />
                  <ChatIcon />
                </div>
                <div style={{ bottom: '9vh', position: 'relative' }}>
                  <MobCopyright />
                </div>
              </div>}
          </div>
          <div class="fixed-bottom">
            <MobileFooter name="Documents"/>
          </div>
      </div>
    )
  }
}
  