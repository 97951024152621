import { Fab } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import SendIcon from '@material-ui/icons/Send'
import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import ReadMoreReact from 'read-more-react'
import './ChatBox.css'

class ChatSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chat: [],
      message: this.props.location.state.message ? this.props.location.state.message : '',
      chats: [],
      fullScreen: [],
      searched: ''
    }
  }
  componentDidMount() {
    if (this.props.location.state.message !== '') {
      this.sendMessage(true)
    }
  }

  render() {
    console.log()
    return (
      <div>
        <div class="h_new">
          <div class="h_left_icon_div">
            <h1 style={{ color: '#fff' }}>AskCaryn</h1>
          </div>
          <div>
            <h1 className="askCarynHeader">YOUR AI-POWERED ASSISTANT</h1>
          </div>
          <div>
            <CloseIcon onClick={() => this.props.history.push('/')} style={{ color: '#fff', cursor: 'pointer' }} />
          </div>
        </div>
        <div className="askCarynSearchTab">
          <Form.Control
            placeholder="Type your request ..."
            value={this.state.message}
            style={stylesheet.searchInputBox}
            onChange={e => this.setState({ message: e.target.value })}
          />
          <div className="search-icon-caryn">
            <Fab style={{ backgroundColor: '#543379', width: 40, height: 40 }}>
              <SendIcon style={{ color: '#fff', fontSize: 20 }} onClick={() => this.sendMessage(true)} />
            </Fab>
          </div>
        </div>
        <div class="web_Chat_container" style={{ paddingTop: 100, backgroundColor: '#fff', height: this.state.searched ? '' : '100vh' }}>
          {this.state.searched.answers &&
            this.state.searched.answers.map((response, i) => (
              <div style={stylesheet.searchResponse}>
                <div>
                  <p className="searchfont">
                    {this.state.fullScreen[i] ? (
                      response.context
                    ) : (
                      <ReadMoreReact text={response.context} ideal={200} readMoreText={'...'} />
                    )}
                  </p>
                  {this.state.fullScreen[i] && (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: '#543379', color: '#fff' }}
                      onClick={() => window.open(response.meta.url)}
                    >
                      Read More
                      <ChevronRightIcon style={{ fontSize: 25 }} />
                    </Button>
                  )}
                </div>
                <div>
                  {this.state.fullScreen[i] ? (
                    <KeyboardArrowUpIcon onClick={() => this.fullScreen(i)} style={{ cursor: 'pointer' }} />
                  ) : (
                    <KeyboardArrowDownIcon onClick={() => this.fullScreen(i)} style={{ cursor: 'pointer' }} />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }
  fullScreen = i => {
    if (this.state.fullScreen[i]) {
      var fullScreen = this.state.fullScreen
      fullScreen[i] = false
      this.setState({ fullScreen: fullScreen })
    } else {
      var fullScreen = this.state.fullScreen
      fullScreen[i] = true
      this.setState({ fullScreen: fullScreen })
    }
  }
  navigate = intent => {
    this.props.navigate(intent)
  }
  sendMessage = e => {
    if (e !== true) e.preventDefault()
    if (this.state.message !== '') {
      this.apiResponse(this.state.message)
    }
  }
  apiResponse = message => {
    ChatInput.map(res => {
      if (res.query === message) {
        this.returnRespone(res)
      }
    })
  }
  returnRespone = response => {
    this.setState({ searched: response })
  }
}
export default ChatSearch

const stylesheet = {
  searchInputBox: {
    borderRadius: 40,
    paddingRight: 40,
    paddingLeft: 20,
    borderColor: '#420045',
    borderWidth: 'revert'
  },
  searchResponse: {
    backgroundColor: '#EEEEEE',
    padding: 15,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    fontWeight: 20
  }
}

const ChatInput = [
]
