import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment'
import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Modal } from 'react-bootstrap'
import axios from 'axios'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Buttons from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import CloseIcon from '@material-ui/icons/Close'
import CommonLoader from '../../CommonLoader'
import './SimpleAccordion.css'
const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    padding: '0px',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#ffffff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 120,
    paddingLeft: '5px',
    paddingRight: '5px',
    '&$expanded': {
      minHeight: 125
    }
  },

  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails)
export default class ContributionmyneedsAccordion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: '',
      formatedData: props.data,
      showModal: false,
      modalData: null,
      expansionPanelOpen: false,
      contributors:null,
      loader:false
    }
    console.log('Accordion pros', this.state.formatedData)

    console.log('=========props data isss==========', this.props)
  }

  handleChange = panel => (event, expanded) => {
    console.log('expanded====', panel, expanded)
    this.setState({ expanded: expanded ? panel : false })
  }

  openModalInfo(dataIndex) {
    this.setState({
      showModal: true,
      modalData: this.state.formatedData[dataIndex]
    })
  }

  closeModalInfo() {
    this.setState({
      showModal: false
    })
  }


  openUrl=(id)=>{
    let request = {
      username: '13o89ebh8v09h57uq29ue5on7g',
      password: '6ncg3ckkb9bogia75h93ehqi8d9id15ppg5dn73e5shs0h3cq0f'
    }
  
    axios.post("https://netwelldevshareplus.auth.us-east-2.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=13o89ebh8v09h57uq29ue5on7g&scope=apiauthidentifier/member.read", {}, {
      auth: request,
      headers: { "Content-Type":"application/x-www-form-urlencoded"}
     }).then((response) => {
      axios.get(`https://klbv8hqfgl.execute-api.us-east-2.amazonaws.com/v0/member-report/eos?search-string=${id}&bucket=eos-devenv`, {
        headers: {
          'x-api-key': 'lIIwjqkmZF3V5T2Mk8qYG3aUStltuWQAaPkpq9JL',
          token: response.data.access_token
        }
      }).then(response => {
        if(response.data[0]){
          window.open(Object.keys(response.data[0]).map(k => response.data[0][k])[0])
      
        }else{
          this.setState({showModal:true, message:"No EOS data available. This expense did not quality for any contribution."})
      
        }
      })
      
    })
  }



   setOpenpopups =(billkey) => {
    this.setState({loader: true})
    let request = {
      username: '4aah9404bptfvbnnvj0jtn06jo',
      password: 'u4mlm6tmk15rv14e2pm0ivagv66ocm3nopphc56hb5p6vkl4tk1'
    }
    // setLoader(true);
    axios.post("https://prodshareplus.auth.us-east-2.amazoncognito.com/token?grant_type=client_credentials&client_id=4aah9404bptfvbnnvj0jtn06jo&scope=apiauthidentifier/member.read", {}, {
      auth: request,
      headers: { "Content-Type":"application/x-www-form-urlencoded"}
     }).then((response) => {
      axios.get(`https://2btt51qv59.execute-api.us-east-2.amazonaws.com/v0/subcriber/contributors-detail?needsId=${billkey}`, {
        headers: {
          'x-api-key': 'y29gwmfNNj4ALv9jE5Hzk3V3CBUsMOnaaexsS4fO',
          token: response.data.access_token
        }
      }).then(response => {
        let deencrpt = JSON.parse(atob(response.data))
        this.setState({loader: false})
        if(deencrpt){
          if(deencrpt.length>0){
            this.setState({contributors:deencrpt, openpopup:true, loader: false})
          }else{
            this.setState({showModal:true, message:"No contribution data available. This expense did not quality for any contribution."})
          }
        }else{
          this.setState({contributors:null, openpopup:false, loader: false})
        }
      }).catch(err=>{
        this.setState({showModal:true, message:"Invalid Request", loader: false})
      })
    }).catch(err=>{
      this.setState({showModal:true, message:"Invalid Request", loader: false})
    })
  }  


  //--- To get updated props on loadMore Btn click
  componentWillReceiveProps(nextProps) {
    this.setState({ formatedData: nextProps.data })
  }

  getDate(date) {
    const isDateValid = moment(date)['_isValid']
    if (isDateValid) {
      return moment(date).format('MMMM D, YYYY')
    }
    return date
  }

  numberWithCommas(x) {
    if (x) {
      if (x.toString().startsWith('$')) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    } else {
      return 'NA'
    }
  }

  floatconversion = x => {
    if (x) {
      if (x.toString().startsWith('$')) {
        let substr = x.substr(1, x.length)
        return (
          '$' +
          parseFloat(substr)
            .toFixed(2)
            .toString()
        )
      } else {
        return x.toFixed(2)
      }
    } else {
      return undefined
    }
  }

  render() {
    return (
      <div>
            {this.state.loader ? <CommonLoader/> : null}
        {this.state.formatedData.map((data, index) => (
          <Accordion expanded={this.state.expanded === 'panel' + index} onChange={this.handleChange('panel' + index)}>
            <AccordionSummary
              //expandIcon={<KeyboardArrowRightIcon  style={{marginLeft:'-25px'}}/>}
              expandIcon={
                <ExpandMoreIcon
                  className="collapseIcon"
                  onClick={() => {
                    this.setState({
                      expansionPanelOpen: !this.state.expansionPanelOpen
                    })
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="panelSummary"
            >
               <Grid container>
                <Grid item xs={8} className="itemWrap">
                  <div className="Expense-Number221">{data.ExpenseNumberLabel}</div>
                  <div className="Expense-Number211">{data.ExpenseNumberVal}</div>
                </Grid>

                <Grid item xs={4} className="itemWrap">
                  {(data.status == 'Paid' || data.status == 'Denied' || data.status=="Completed")? (
                    <span className={'status_button Audit'}>{data.status}</span>
                  ) : (
                    <span className={'status_button Logged'}>{data.status}</span>
                  )}
                </Grid>

                <Grid item xs={12} className="itemWrap">
                  <div className="Expense-Number221">{data.ProviderLabel}</div>
                  <div className="Expense-Number211">{data.ProviderVal}</div>
                </Grid>

                <Grid item xs={12} className="itemWrap">
                  <div className="Expense-Number221">{data.row7}</div>
                  <div className="Expense-Number211">{data.rowval7}</div>
                </Grid>

                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">{data.DateOfServiceLabel}</div>
                  <div className="Expense-Number211">
                    {this.getDate(data.DateOfServiceVal)}
                  </div>
                </Grid>

                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">{data.ChargedLabel}</div>
                  <div className="Expense-Number211">
                    {this.numberWithCommas(this.floatconversion(data.ChargedVal))}
                  </div>
                </Grid>

              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ paddingLeft: '26px' }}>
              <Grid container>
                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">{data.MoreDetail.BillChargeLabel}</div>
                  <div className="Expense-Number211">{data.MoreDetail.BillChargeVal}</div>

                  {/* <div className="Expense-Number211 view_number" onClick={()=>this.openModalInfo(index)}>VIEW</div> */}
                </Grid>
                {/* } */}

                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">{data.MoreDetail.IneligibleforSharingLabel}</div>
                  <div className="Expense-Number211">{data.MoreDetail.IneligibleforSharingVal}</div>
                </Grid>
                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">{data.MoreDetail.DiscountToBilledChargesLabel}</div>
                  <div className="Expense-Number211">{data.MoreDetail.DiscountToBilledChargesVal}</div>
                </Grid>

                <Grid item xs={5} className="itemWrap">
                  <div className="Expense-Number221">{data.MoreDetail.EligibleforSharingLabel}</div>
                  <div className="Expense-Number211">{data.MoreDetail.EligibleforSharingVal}</div>
                </Grid>

                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">{data.MoreDetail.NonShareableAmountLabel}</div>
                  <div className="Expense-Number211">{data.MoreDetail.NonShareableAmountVal}</div>
                </Grid>
                
                <Grid item xs={5} className="itemWrap">
                  <div className="Expense-Number221">{data.MoreDetail.ConsultationFeeLabel}</div>
                  <div className="Expense-Number211">{data.MoreDetail.ConsultationFeeVal}</div>
                </Grid>

                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">{data.MoreDetail.ShareableAmountLabel}</div>
                  <div className="Expense-Number211">{data.MoreDetail.ShareableAmountVal}</div>
                </Grid>

                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">{data.MoreDetail.MemberResponsibilityLabel}</div>
                  <div className="Expense-Number211">{data.MoreDetail.MemberResponsibilityVal}</div>
                </Grid>

                {/* <Grid item xs={5} className="itemWrap">
                  <div className="Expense-Number221">Paid Amount</div>
                  <div className="Expense-Number211">{data.MoreDetail.SharedByUHFVal}</div>
                </Grid> */}
            
              
                {/* <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Explanation of Sharing (EOS)</div>
                  <div className="number-View-download-disabled " onClick={() =>false && this.openUrl(data.ExpenseNumberVal)}>VIEW/DOWNLOAD</div>
                </Grid> */}
                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Your Community Sharing <Tooltip title={`Sharing Members, when they experience eligible medical expenses, may submit those needs for sharing among other members. Please refer to the Sharing Guidelines for more information.`}>
                  <svg style={{width:20}} class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg>
                  </Tooltip></div> 
                  <div className="number-View-download" onClick={() =>this.setOpenpopups(data.ExpenseNumberVal)}>VIEW</div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}

<Modal dialogClassName="my-modal" show={this.state.openpopup} onHide={()=>this.setState({openpopup: false})} centered className="ChangPayModal" backdrop="static">
          <Modal.Header style={{height:56, fontFamily:"Lato"}}>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <h6 style={{color:"#373a3c"}}>Your Community Sharing</h6>
                <IconButton aria-label="close" style={{ marginTop: '-13px', marginRight:"-15px" }} onClick={() =>this.setState({openpopup: false, contributors:null})}>
                  <CloseIcon />
                </IconButton>
              </Grid>
          </Modal.Header>
          <Modal.Body>
            <div style={{padding:15}}> 
            <TableContainer  component={Paper}>
              <Table  sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow style={{borderBlockColor:"black"}}>
                    <TableCell style={{borderColor:"#8c827a",padding:0}}><p style={{width:"auto", marginTop:16, fontWeight:"normal", color: "#454d58", paddingLeft:10}}>Member</p></TableCell>
                    <TableCell align="right" style={{borderColor:"#8c827a" ,padding:0}}><p style={{width:"auto", marginTop:16,fontWeight:"normal", color: "#454d58", paddingRight:10}}>Contribution Amount</p></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{maxHeight:173, overflowY: 'auto', display:'block', width:"189%"}}>
                
                  {this.state.contributors && this.state.contributors && this.state.contributors.map((res, i)=> <TableRow key={i}>
                      <TableCell align="left" style={{borderColor:this.state.contributors.length==i+1?"#8c827a":"", width:"123%", padding:0}}><p style={{width:"auto", marginTop:16, fontWeight:"500", color:"black", paddingLeft:10}}>{res.first_name} {res.last_name[0]}</p></TableCell>
                      <TableCell align="right" style={{borderColor:this.state.contributors.length==i+1?"#8c827a":"", width:"123%",padding:0}}><p style={{width:"auto", marginTop:16, fontWeight:"500", color:"black", paddingRight:10}}>{res.contribution_amount}</p></TableCell>
                    </TableRow>)}
                  
                </TableBody>
                {this.state.contributors && 
                  <TableRow style={{backgroundColor:"#F6F6F6"}} key={"total"}>
                      <TableCell align="left"><p style={{width:"auto", marginTop:16, fontWeight:"500", color:"black", paddingLeft:10,padding:0}}>Total Contribution</p></TableCell>
                      <TableCell align="right"><p style={{width:"auto", marginTop:16, fontWeight:"500", color:"black", paddingRight:10,padding:0}}>
                      ${this.state.contributors.reduce((accumulator, currentValue) => {
                          return accumulator + parseFloat(currentValue.contribution_amount.replace(/[^0-9.]/g, '')) ;
                        }, 0).toFixed(2)}
                        </p></TableCell>
                    </TableRow>}  
              </Table>
            </TableContainer>

            </div>
          </Modal.Body>
          <Modal.Footer>
          <Buttons variant="secondary" style={{ marginRight: '15px' }} 
                onClick={() => this.setState({openpopup: false, contributors:null})}
                class="reimbursement-ButtonBGsave">
                  CLOSE
                </Buttons>
          </Modal.Footer>
          </Modal>

        <Modal show={this.state.showModal}>
          <Modal.Header closeButton>
            <Modal.Title>Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="ModalWrapper">
             <p>{this.state.message}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons variant="secondary"   class="reimbursement-ButtonBGsave" onClick={() => this.setState({message:"", showModal:false})}>
              Close
            </Buttons>
         
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const sampleData2 = [
  { id: 733, planId: '7006', idcardField: 'Telemedicine', fieldValue: '$0' },
  { id: 735, planId: '7006', idcardField: 'PCP Office Visit', fieldValue: '$35' },
  { id: 736, planId: '7006', idcardField: 'Urgent Care', fieldValue: '$100' },
  { id: 737, planId: '7006', idcardField: 'ER Visit', fieldValue: '$400' }
]
