import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import customStyle from '../../components/healthqn/CSS/stylesheet_UHS'
import firebase from '../../firebase'
import { getAnnouncemetDetails, getEducationDetails } from '../ApiCall'
import EduIcon from '../Images/LeftDrawer Icon/member_education_icon.svg'
import EduIconActive from '../Images/LeftDrawer Icon/member_education_icon_active.svg'
import KCLogo from '../Images/KC_logo.png'
import UHFLogo from '../Images/UHF_logo.png'
import AlykaLogo from '../Images/Alyka_logo.png'
import './WebScreens.css'

const NextButton = withStyles(customStyle.viewBtn)(Button)

class EducationWindowCardx extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sidebarOpen: false,
            dockval: false,
            rightslider: false,
            rightdockval: false,
            showHealthCard: false,
            bigcard: '',
            smallcard: '',
            rightcard: '',
            x: false,
            yellowPopup: false,
            username: localStorage.getItem('subscriberName'),
            digitalcard: false,
            documentsmodal: false,
            contactmodal: false,
            mqmoduleAlert: null,
            mqloader: false,
            myneedsfooter: false,
            contactCardData: null,
            documentCardData: null,
            tempNotAvailableModal: false,
            notificationData: [],
            unReadData: [],
            notificationCount: 0,
            anchorEl: null,
            open: false,
            show: false,
            showhealthtools: false,
            content: '',
            header: '',
            publishDate: '',
            URL: null,
            Type: null,
            videoUrl: null,
            isShowModal: false,
        }
    }

    componentDidMount() {
        this.getEducationDetails()
        var popupShow = localStorage.getItem('popupShow')
        this.setState({ yellowPopup: popupShow })
        const msg = firebase.messaging.isSupported() ? firebase.messaging() : null
        if (msg) {
            msg.onMessage(payload => {
                this.getEducationDetails()
                const myEvent = new CustomEvent('myevent', {
                    detail: {},
                    bubbles: true,
                    cancelable: true,
                    composed: false
                })
                document.dispatchEvent(myEvent)
                console.log('onMessage:+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++home ', payload)
            })
        }
    }

    getEducationDetails = () => {
        getEducationDetails().then(res => {
            if (res.data && res.data.response) {
                this.setState({
                    notificationData: res.data.response.notificationDetails,
                    notificationCount: res.data.response.notificationDetails.length
                })
            } else {
                this.setState({ notificationData: [] })
            }
        })
    }

    onSectionClick = (title, url) => {
        window.open(url)
    }

    getDateInUTC = (date, getInMillisecs) => {
        if (date) {
            let newDateTime = new Date(date)
            return new Date(newDateTime)
        }
        return date
    }

    dateformat(date) {
        const enrollmentDate = this.getDateInUTC(date, true)
        let nowdate = this.getDateInUTC(new Date(), true)
        var day = moment(enrollmentDate).format('DD')
        var mon = moment(enrollmentDate).format('MM')
        var year = moment(enrollmentDate).format('YYYY')
        var date = mon + '/' + day + '/' + year
        var todayDate = moment(nowdate).format('MM/DD/YYYY')
        let hr = moment(enrollmentDate).format('hh')

        if (date !== todayDate) {
            return moment(date).format('MMM DD, YYYY')
        } else {
            if (hr == '00') {
                return moment(enrollmentDate).format('m') + 'mins'
            }
            if (hr > 12) {
                return moment(enrollmentDate).format('hh:mm A')
            } else {
                return moment(enrollmentDate).format('hh:mm A')
            }
        }
    }

    render() {
        return (
            <div>
                <Grid container>
                    <Grid item md={3}>
                        {this.state.notificationData && this.state.notificationData.length > 0 ? (
                            <div>
                                <div class="cardwallet_back_notification">
                                    <div class="NotificationCard-BG text-center" style={{ display: 'block' }}>
                                        <div class="cardwallet_onover_backdivMain" style={{ cursor: 'auto' }}>
                                            <div style={{ display: 'flex', width: '100%', textAlign: 'left' }}>
                                                <img src={EduIconActive} class="cardwaalet_img_backside1" />
                                                <div class="notification_label_backside1" style={{ paddingTop: '8px' }}>
                                                    Member Education Panel
                                                </div>
                                            </div>
                                            <div style={{ textAlign: 'right', width: '64%' }}>
                                                <Link
                                                    to="/Education"
                                                    style={{ textDecoration: 'none', float: 'right', marginRight: '-113px', marginTop: '-23px' }}
                                                >
                                                    {' '}
                                                    <span className="viewAll1">View all</span>
                                                </Link>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                height: ' 18.5vw',
                                                overflow: 'auto',
                                                marginTop: '0px'
                                            }}
                                        >
                                            {this.state.notificationData.slice(0,5).map((data, idx) => {
                                                return (
                                                  <div
                                                    key={idx}
                                                    class="cardwallet_onover_backdiv"
                                                    onClick={() => this.onSectionClick(data.title, data.urlPath)}
                                                  >
                                                    <div>
                                                      {data.logo == 'UHF' ? (
                                                        <img src={UHFLogo} class="Notification_category_img" style={{ width: '2.7vw' }} />
                                                      ) : data.logo == 'Alyka Health' ? (
                                                        <img src={AlykaLogo} class="Notification_category_img" style={{ width: '2.7vw' }} />
                                                      ) : data.logo == "Kelly's Choice" ? (
                                                        <img src={KCLogo} class="Notification_category_img" style={{ width: '2.7vw' }} />
                                                      ) : null}
                                                    </div>
                                                    <div class="notification_category_Message">
                                                      <p class="notification_category_label notification_category_title">
                                                        <span style={{ width: '80%', fontSize: '13px' }}>{data.title}</span>
                                                        <span
                                                          style={{ width: '38%', color: '#8c827a' }}
                                                          className="notification_details_Date"
                                                        >
                                                          {this.dateformat(data.createdDate)}
                                                        </span>
                                                      </p>
                                                      <div class="notification_category_label">{data.message}</div>
                                                    </div>
                                                  </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="cardwallet_back_notification">
                                <div className="NotificationCard-BG text-center">
                                    <div className="cardwallet_onover_backdivMain" style={{ display: 'flex' }}>
                                        <img src={EduIcon} className="cardwaalet_img_backside1" />
                                        <div className="notification_label_backside1">Member Education Panel</div>
                                        <div style={{ textAlign: 'right' }}>
                                            <Link to="/Education" style={{ textDecoration: 'none', float: 'right', marginRight: '-56px' }}>
                                                {' '}
                                                <span className="viewAll1">View all</span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div style={{ padding: '35px' }}>
                                        <div className="tempModalTxt">Watch this space for upcoming articles and videos on health and wellness.</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Grid>
                </Grid>

                <Modal size='xl' show={this.state.isShowModal} centered className="ChangPayModal paymentWalletModal" backdrop="static">
                    <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                        <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{ flexWrap: 'nowrap' }}>
                            <span class="Change-Payment-Method" style={{ lineHeight: '1.3' }}>
                                Video Title
                            </span>
                            <IconButton aria-label="close" onClick={() => this.setState({ isShowModal: false })} style={{ marginTop: '-13px' }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <div>
                            <iframe
                                src={this.state.videoUrl}
                                title="Common Module"
                                onLoad={() => {
                                    this.setState({
                                        isShowModal: true
                                    })
                                }}
                                style={{
                                    width: '725px',
                                    height: '470px',
                                    border: 'none',
                                    margin: 'auto'
                                }}
                            ></iframe>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
export default EducationWindowCardx
