import React, { Component } from 'react'
import { getSendNeedsToContactAddress } from '../../ApiCall'
export default class IDCardBackViewMob extends Component {
  constructor(props) {
    super(props)
    this.state = {
      network: this.props.network
    }
    console.log('ID CARD BACK VIEW MOBILE==', this.props)
  }
  render() {
    return (
      <div class="bv">
        {/* <div class="bv_top_image_container">
                <img src={require('../../Images/Card/thank-you-for.png')} class="bv_thank_image"/>
                <img src={require('../../Images/Card/sharing.png')} class="bv_sharing_image"/>
            </div> */}

        <div class="bv_details_text_div">
          {/* ========================Top Blue container 1st  =================== */}
          {
                (() => {
                  var client_id_locale = localStorage.getItem("CLIENT_ID");
                    if(client_id_locale=="5541"||client_id_locale=="4377") {
                      return (
                        <div>
                          <div class="bv_bluetext_common">
                            For Pre-Notification call: (877) 987-1233
                          </div>
                          {/* <div class="bv_bluetext_common">For Customer Service call: {this.props.contactNumber}</div>
                          <div class="bv_bluetext_common">To find a provider visit FindProvider.UniversalHealthFellowship.org</div> */}
  
                          <div class="AFA_3rdtext">
                            <div>
                              <div class="bv_bluetext_common">
                                For Member Service call: (877) 987-1233
                              </div>
                              <div class="bv_bluetext_common">
                                To find a provider visit
                                FindProvider.UniversalHealthFellowship.org
                              </div>
                            </div>
  
                            <div class="AFA_id_no_div">EDI #40585</div>
                          </div>
                        </div>
                      );
                        } else if (client_id_locale=="3211"||client_id_locale=="3098") {
                          return (
                            <div>
                              {/* <div class="bv_bluetext_common">
                                For Preventive Services Appointments visit PreventiveServices.UniversalHealthFellowship.org
                              </div>
                              <div class="bv_bluetext_common">To find a provider visit FindProvider.UniversalHealthFellowship.org</div>
                              <div class="bv_bluetext_common">Providers, send needs requests to: P.O Box 211223, Eagan, MN 55121</div>
                              <div class="bv_bluetext_common">For Customer Service call: {this.props.contactNumber}</div> */}
      
                              <div class="bv_bluetext_common">
                                For Pre-Notification call: (888) 366-6243
                              </div>
      
                              <div class="AFA_3rdtext">
                                <div>
                                  <div class="bv_bluetext_common">
                                    For Member Service call: (888) 366-6243
                                  </div>
                                  <div class="bv_bluetext_common">
                                    To find a provider visit
                                    FindProvider.UniversalHealthFellowship.org
                                  </div>
                                </div>
      
                                <div class="AFA_id_no_div">EDI #53684</div>
                              </div>
                            </div>
                          );
                        }else if(client_id_locale=="4350"){
                          return (
                            <div>
                              <div class="bv_bluetext_common">
                                For Preventive Services Appointments visit:
                                PreventiveServices.UniversalHealthFellowship.org
                              </div>
                              {/* <div class="bv_bluetext_common">For Customer Service call: {this.props.contactNumber}</div> */}
      
                              {/* <div class="AFA_3rdtext">
                                    <div class="bv_bluetext_common">To find a provider visit https://azfmc.com/providersearch</div>
                                    <div class="AFA_id_no_div">AFMC EDI #86062</div>
                              </div> */}
      
                              <div class="AFA_3rdtext">
                                <div>
                                  <div class="bv_bluetext_common">
                                    For Member Service call:{" "}
                                    {this.props.contactNumber}
                                  </div>
                                  <div class="bv_bluetext_common">
                                    To find a provider visit
                                    https://azfmc.com/providersearch
                                  </div>
                                </div>
      
                                <div class="AFA_id_no_div">AFMC EDI #86062</div>
                              </div>
                            </div>
                          );
                        } 
                        else {
                          return (
                            <div>
                              <div class="bv_bluetext_common">
                                For Pre-Notification call: (888) 366-6243
                              </div>
      
                              <div class="AFA_3rdtext">
                                <div>
                                  <div class="bv_bluetext_common">
                                    For Member Service call: (877) 987-1233
                                  </div>
                                  <div class="bv_bluetext_common">
                                    To find a provider visit
                                    FindProvider.UniversalHealthFellowship.org
                                  </div>
                                </div>
      
                                <div class="AFA_id_no_div">EDI #53684</div>
                              </div>
                            </div>
                          );
                        }
                })()  
            } 
         

          {/* ========================Top Blue container 2st  =================== */}
          {
                (() => {
                  var client_id_locale = localStorage.getItem("CLIENT_ID");
                    if(client_id_locale=="5541"||client_id_locale=="4377") {
                      return (
                        <div class="bv_second_container">
                          <div class="bv_bluetext_common">
                            Providers, send needs to: PO Box 21082 Eagan, MN 55121
                          </div>
                          <div class="bv_blacktext_common margintop_small">
                            Pre-notification is required before these procedures
                            will be eligible for sharing: All Inpatient Hospital
                            Confinements, All Surgical Procedures (Inpatient,
                            Outpatient & Ambulatory, Organ & Tissue Transplant
                            Services), All invasive Cardiac Procedures, Cancer
                            Treatment & Oncology Services, Imaging (MRI, CT Scan,
                            MRA, PET Scan), Allergy Testing over $500 in expected
                            charges per program year, Home Health Care Services,
                            Carpal Tunnel Treatments. In addition,
                            Pre-certification to confirm medical necessity is
                            required before these procedures may be eligible for
                            sharing: Transplant of any organ or tissue, a coronary
                            bypass or graft of any kind, or a knee or hip
                            replacement.
                            <span class="bv_black_bold_text">
                              {" "}
                              <br /> For Medical Emergencies Seek Immediate
                              Medical Help.
                            </span>
                          </div>
                          <div class="bv_blacktext_common margintop_small">
                            Participating member assignment of eligible medical
                            expense sharing payment is permitted as consideration
                            in full for services rendered. Reimbursement for
                            hospital facility services is determined at a
                            percentage of the facility’s Medicare allowable
                            amounts (140% for Inpatient and 155% for Outpatient
                            Services), or, in the absence of an applicable CMS fee
                            schedule, in accordance with published UHF Sharing
                            Guidelines. Acceptance of sharing payment for Eligible
                            Expenses constitutes waiver of facility/provider right
                            to balance bill patient.
                          </div>
                          <div class="bv_blacktext_common margintop_small">
                            See applicable Sharing Guidelines for more details.{" "}
                            <span class="bv_black_bold_text">
                              Universal Health Fellowship
                            </span>{" "}
                            is a not-for-profit ministry that facilitates medical
                            expense sharing through Universal HealthShare
                            Programs. Universal HealthShare is not insurance and
                            does not guarantee that eligible medical bills will be
                            shared or otherwise paid. Universal HealthShare is not
                            a discount card or program.
                          </div>
                        </div>
                      );
                        } else if (client_id_locale=="3211"||client_id_locale=="3098") {
                          return (
                            <div class="bv_second_container">
                              <div class="bv_bluetext_common">
                                Providers, send needs requests to: P.O. Box 211223,
                                Eagan, MN 55121
                              </div>
                              <div class="bv_blacktext_common margintop_small">
                                Pre-notification is required before these procedures
                                will be eligible for sharing: All Inpatient Hospital
                                Confinements, All Surgical Procedures (Inpatient,
                                Outpatient & Ambulatory, Organ & Tissue Transplant
                                Services), All invasive Cardiac Procedures, Cancer
                                Treatment & Oncology Services, Imaging (MRI, CT Scan,
                                MRA, PET Scan), Allergy Testing over $500 in expected
                                charges per program year, Home Health Care Services,
                                Carpal Tunnel Treatments. In addition,
                                Pre-certification to confirm medical necessity is
                                required before these procedures may be eligible for
                                sharing: Transplant of any organ or tissue, a coronary
                                bypass or graft of any kind, or a knee or hip
                                replacement.
                                <span class="bv_black_bold_text">
                                  {" "}
                                  <br /> For Medical Emergencies Seek Immediate
                                  Medical Help.
                                </span>
                              </div>
                              <div class="bv_blacktext_common margintop_small">
                                Participating member assignment of eligible medical
                                expense sharing payment is permitted as consideration
                                in full for services rendered. Reimbursement for
                                hospital facility services is determined at a
                                percentage of the facility’s Medicare allowable
                                amounts (140% for Inpatient and 155% for Outpatient
                                Services), or, in the absence of an applicable CMS fee
                                schedule, in accordance with published UHF Sharing
                                Guidelines. Acceptance of sharing payment for Eligible
                                Expenses constitutes waiver of facility/provider right
                                to balance bill patient.
                              </div>
                              <div class="bv_blacktext_common margintop_small">
                                See applicable Sharing Guidelines for more details.{" "}
                                <span class="bv_black_bold_text">
                                  Universal Health Fellowship
                                </span>{" "}
                                is a not-for-profit ministry that facilitates medical
                                expense sharing through Universal HealthShare
                                Programs. Universal HealthShare is not insurance and
                                does not guarantee that eligible medical bills will be
                                shared or otherwise paid. Universal HealthShare is not
                                a discount card or program.
                              </div>
                            </div>
                          );
                        } else if(client_id_locale=="4350"){
                          return (
                            <div class="bv_second_container">
                              <div class="bv_bluetext_common">
                                Providers, send needs requests to:{" "}
                                {getSendNeedsToContactAddress(this.props.network)}
                              </div>
                              <div class="bv_blacktext_common margintop_small">
                                Pre-notification is required before these procedures
                                will be eligible for sharing: All Inpatient Hospital
                                Confinements, All Surgical Procedures (Inpatient,
                                Outpatient and Ambulatory, Organ and Tissue Transplant
                                Services) Cancer Treatment and Oncology Services,
                                Independent Lab Tests and Imaging, Home Health Care
                                Services, Carpal Tunnel Treatments. In addition,
                                Pre-certification to confirm medical necessity is
                                required before these procedures may be eligible for
                                sharing: Transplant of any organ or tissue, a coronary
                                bypass or graft of any kind, or a knee or hip
                                replacement.
                                <span class="bv_black_bold_text">
                                  {" "}
                                  <br></br>For Medical Emergencies Seek Immediate
                                  Medical Help.
                                </span>
                              </div>
      
                              <div class="bv_blacktext_common margintop_small">
                                Participating member assignment of eligible medical
                                expense sharing payment is permitted as consideration
                                in full for services rendered. Reimbursement for
                                hospital facility services is determined at a
                                percentage of the facility’s Medicare allowable
                                amounts (140% for Inpatient and 155% for Outpatient
                                Services), or, in the absence of an applicable CMS fee
                                schedule, in accordance with published UHF Sharing
                                Guidelines. Acceptance of sharing payment for Eligible
                                Expenses constitutes waiver of facility/provider right
                                to balance bill patient.
                              </div>
      
                              <div class="bv_blacktext_common margintop_small">
                                See applicable Sharing Guidelines for more details.{" "}
                                <span class="bv_black_bold_text">
                                  Universal Health Fellowship
                                </span>{" "}
                                is a not-for-profit ministry that facilitates medical
                                expense sharing through Universal HealthShare
                                Programs. Universal HealthShare is not insurance and
                                does not guarantee that eligible medical bills will be
                                shared or otherwise paid. Universal HealthShare is not
                                a discount card or program.
                                {/* <span class="AFA_bottom_id">AFMC EDI #86062</span> */}
                              </div>
                            </div>
                          );
                        }else{
                          return (
                            <div class="bv_second_container">
                              <div class="bv_bluetext_common">
                                Providers, send needs requests to: P.O. Box 211223,
                                Eagan, MN 55121
                              </div>
                              <div class="smartshare_container_middle_text">
                              {
                (() => {
                    if(this.state.network=="smartshare50") {
                            return (
                              <div class="bv_black_italic_text">
                                All medical services for the Healthy Life
                                    EasyShare Program 50 are limited to an annual
                                    maximum of $50,000 per member.
                               </div> 
                            )
                        } else if (this.state.network=="smartshare25") {
                            return (
                              <div class="bv_black_italic_text">
                                 All medical services for the Healthy Life
                                  EasyShare Program 25 are limited to an annual
                                  maximum of $25,000 per member.
                                </div>
                            )
                        } else {
                            return (
                              <div class="bv_blacktext_common margintop_small">
                              Pre-notification is required before these procedures
                              will be eligible for sharing: All Inpatient Hospital
                              Confinements, All Surgical Procedures (Inpatient,
                              Outpatient & Ambulatory, Organ & Tissue Transplant
                              Services), All invasive Cardiac Procedures, Cancer
                              Treatment & Oncology Services, Imaging (MRI, CT Scan,
                              MRA, PET Scan), Allergy Testing over $500 in expected
                              charges per program year, Home Health Care Services,
                              Carpal Tunnel Treatments. In addition,
                              Pre-certification to confirm medical necessity is
                              required before these procedures may be eligible for
                              sharing: Transplant of any organ or tissue, a coronary
                              bypass or graft of any kind, or a knee or hip
                              replacement.
                              <span class="bv_black_bold_text">
                                {" "}
                                <br /> For Medical Emergencies Seek Immediate
                                Medical Help.
                              </span>
                            </div>
                            )
                        }
                })()  
            } 
</div>


                              
      
                              <div class="bv_blacktext_common margintop_small">
                                Participating member assignment of eligible medical
                                expense sharing payment is permitted as consideration
                                in full for services rendered. Reimbursement for
                                hospital facility services is determined at a
                                percentage of the facility’s Medicare allowable
                                amounts (140% for Inpatient and 155% for Outpatient
                                Services), or, in the absence of an applicable CMS fee
                                schedule, in accordance with published UHF Sharing
                                Guidelines. Acceptance of sharing payment for Eligible
                                Expenses constitutes waiver of facility/provider right
                                to balance bill patient.
                              </div>
      
                              <div class="bv_blacktext_common margintop_small">
                                See applicable Sharing Guidelines for more details.{" "}
                                <span class="bv_black_bold_text">
                                  Universal Health Fellowship
                                </span>{" "}
                                is a not-for-profit ministry that facilitates medical
                                expense sharing through Universal HealthShare
                                Programs. Universal HealthShare is not insurance and
                                does not guarantee that eligible medical bills will be
                                shared or otherwise paid. Universal HealthShare is not
                                a discount card or program.
                              </div>
                            </div>
                          );
                        }
                })()  
            } 
        </div>

        {/*{this.state.network == 'smartshare25' ? (
          <div>
            <div class="bv_top_image_container">
              <img src={require('../../Images/Card/thank-you-for.png')} class="bv_thank_image_smartshare" />
              <img src={require('../../Images/Card/sharing.png')} class="bv_sharing_image_smartshare" />
            </div>
            <div class="botom_container_smaratshare">
              <div class="bottom_link_backview">www.UniversalHealthFellowship.org</div>
              <div class="bv_card_no_bottom">
                {this.props.cardId}
                {/* HLES25R022321E030121 
              </div>
            </div>
          </div>
        ) : null}
        {this.state.network == 'smartshare50' ? (
          <div>
            <div class="bv_top_image_container">
              <img src={require('../../Images/Card/thank-you-for.png')} class="bv_thank_image_smartshare" />
              <img src={require('../../Images/Card/sharing.png')} class="bv_sharing_image_smartshare" />
            </div>
            <div class="botom_container_smaratshare">
              <div class="bottom_link_backview">www.UniversalHealthFellowship.org</div>
              <div class="bv_card_no_bottom">
                {this.props.cardId}
                {/* HLES50R022321E030121 
              </div>
            </div>
          </div>
        ) : null}*/}

        {localStorage.getItem("CLIENT_ID")=="5541" ||localStorage.getItem("CLIENT_ID")=="4377"|| localStorage.getItem("CLIENT_ID")=="3211"||localStorage.getItem("CLIENT_ID")=="3098" ? (
          <div class="bottomview_image_and_no">
            <div class="bottom_link_backview">www.UniversalHealthFellowship.org</div>

            <div class="bv_top_image_container">
              <img src={require('../../Images/Card/thank-you-for.png')} class="bv_thank_image" />
              <img src={require('../../Images/Card/sharing.png')} class="bv_sharing_image" />
            </div>

            <div class="bv_card_no_bottom">
              {this.props.cardId}
              {/* HLR022321E030121 */}
            </div>
          </div>
        ) : <div class="bottomview_image_and_no">
        <div class="bottom_link_backview">www.UniversalHealthFellowship.org</div>

        <div class="bv_top_image_container">
          <img src={require('../../Images/Card/thank-you-for.png')} class="bv_thank_image" />
          <img src={require('../../Images/Card/sharing.png')} class="bv_sharing_image" />
        </div>

        <div class="bv_card_no_bottom">
          {this.props.cardId}
          {/* HLR022321E030121 */}
        </div>
      </div>}
      </div>
    )
  }
}
