import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { createMuiTheme, lighten, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import shareNeeds from '../../assets/images/shareNeeds.svg'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import './MyNeeds.css'
import axios from 'axios'
import CommonLoader from '../CommonLoader'

function createData(no, date_of_service, provider, charged, paid, status) {
  return { no, date_of_service, provider, charged, paid, status }
}

const theme = createMuiTheme({
  MuiTableCell: {
    paddingLeft: '30px'
  }
})

const rows = [
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW')
]

function descendingComparator(a, b, orderBy) {
  if (orderBy == 'contribution_amount') {
    if (parseFloat(b[orderBy].replace('$', '')) < parseFloat(a[orderBy].replace('$', ''))) {
      return -1
    }
    if (parseFloat(b[orderBy].replace('$', '')) > parseFloat(a[orderBy].replace('$', ''))) {
      return 1
    }
    return 0
  } else if (orderBy == 'service_date') {
    if (moment(b[orderBy]).isBefore(a[orderBy])) {
      return -1
    }
    if (moment(b[orderBy]).isAfter(a[orderBy])) {
      return 1
    }
    return 0
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const headCells = [
  // { key: 'need_id', id: 'Reference #', numeric: true, disablePadding: false, label: 'Reference #' },
  { key: 'service_date', id: 'service_date', numeric: false, disablePadding: false, label: 'Date' },
  { key: 'contribution_amount', id: 'charged_amount', numeric: true, disablePadding: false, label: 'Contribution Amount' },
  { key: 'icd_10_code_description', id: 'paid_provider_name', numeric: false, disablePadding: false, label: 'Towards Health Need' },
  { key: 'name_initials', id: 'first_name', numeric: true, disablePadding: false, label: ' Member' }
]

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow className="tableHeadRow">
        {headCells.map(headCell => (
          <>
            {headCell.key == 'service_date' ? (
              <TableCell
                key={headCell.key}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.key ? order : false}
                className="sortheadercellColor tableHeader myTableHead"
              >
                <TableSortLabel
                  active={orderBy === headCell.key}
                  direction={orderBy === headCell.key ? order : 'asc'}
                  onClick={createSortHandler(headCell.key)}
                  className="headercellColor tableHeader"
                >
                  {headCell.label}
                  {orderBy === headCell.key ? (
                    <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                key={headCell.key}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.key ? order : false}
                className="headercellColor tableHeader myTableHead"
              >
                <TableSortLabel
                  active={orderBy === headCell.key}
                  direction={orderBy === headCell.key ? order : 'asc'}
                  onClick={createSortHandler(headCell.key)}
                  className="headercellColor tableHeader"
                >
                  {headCell.label}
                  {orderBy === headCell.key ? (
                    <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )}
          </>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%'
  }
}))

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

export default function ContributionDataTable(props) {
  const classes = useStyles()
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('service_date')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const { tableData } = props
  const [contributionData, setContributionData] = React.useState([])
  const [loader, setLoader] = React.useState(false)

  useEffect(() => {
    getcontributionData()
  }, [page, rowsPerPage])

  const getcontributionData = () => {
    let member_id = localStorage.getItem('Member_EMPID')
    let request = {
      username: '4aah9404bptfvbnnvj0jtn06jo',
      password: 'u4mlm6tmk15rv14e2pm0ivagv66ocm3nopphc56hb5p6vkl4tk1'
    }
    setLoader(true)
    axios
      .post(
        'https://prodshareplus.auth.us-east-2.amazoncognito.com/token?grant_type=client_credentials&client_id=4aah9404bptfvbnnvj0jtn06jo&scope=apiauthidentifier/member.read',
        {},
        {
          auth: request,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }
      )
      .then(response => {
        axios
          .get(
            `https://2btt51qv59.execute-api.us-east-2.amazonaws.com/dev/subcriber/member-contributions?memberId=${member_id}&pageNo=${page}&numberOfRows=${rowsPerPage}`,
            {
              headers: {
                'x-api-key': 'y29gwmfNNj4ALv9jE5Hzk3V3CBUsMOnaaexsS4fO',
                token: response.data.access_token
              }
            }
          )
          .then(response => {
            let deencrpt = JSON.parse(atob(response.data))
            if (deencrpt) {
              if (deencrpt.data.length > 0) {
                setContributionData(deencrpt.data)
                setLoader(false)
              } else {
                setLoader(false)
                // this.setState({showModal:true, message:"No contribution data available. Please try again later."})
              }
            } else {
              setLoader(false)
              // this.setState({contributors:null, openpopup:false})
            }
          })
          .catch(err => {
            setLoader(false)
            // this.setState({showModal:true, message:"Invalid Request"})
          })
      })
      .catch(err => {
        setLoader(false)
        // this.setState({showModal:true, message:"Invalid Request"})
      })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDense = event => {
    setDense(event.target.checked)
  }

  const isSelected = name => selected.indexOf(name) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      {loader ? <CommonLoader /> : null}
      {contributionData[0] ?
        <Paper className={'tableContainer ' + classes.paper}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        {/* <div className="tablefixheight"> */}
        <TableContainer style={{ height: 360 }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />

            <TableBody className='myTableHead'>
              {stableSort(contributionData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((tableData, index) => {
                  const isItemSelected = isSelected(tableData.name)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return <Row open={props.open} key={tableData.name} row={tableData} />
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          style={{ position: 'relative' }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={contributionData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      :
      <div className="data_not_found">
        {!loader &&
          <h5 class="noneeds_msg_display">There is no contribution data available at the moment.</h5>
        }
      </div>
      }
    </div>
  )
}

function Row(props) {
  const { row } = props
  const [open, setOpen] = React.useState(props.open)
  const classes = useRowStyles()

  const [modalShow, setModalShow] = React.useState(false)

  const getDate = date => {
    const isDateValid = moment(date)['_isValid']
    if (isDateValid) {
      return moment(date).format('MMMM D, YYYY')
    }
    return date
  }

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const floatconversion = x => {
    return x.toFixed(2)
  }

  const combineDate = (x, y) => {
    if (x == y) {
      return x
    }

    const z = x + '-' + y
    return z
  }

  const convertToString = x => {
    console.log('Type Of X')
    console.log(x)
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <TableRow className={'rowTable ' + classes.root}>
          {/* <TableCell component="th" scope="row" className="rowcellcolor rowTableCell myTableRow">
            {row.need_id ? row.need_id : 'NA'}
          </TableCell> */}
          <TableCell component="th" scope="row" className="sortrowcellcolor rowTableCell myTableRow">
            {row.service_date ? getDate(row.service_date) : 'NA'}
          </TableCell>
          <TableCell align="left" className="rowcellcolor rowTableCell myTableRow">
            {row.contribution_amount ? row.contribution_amount : 'NA'}
          </TableCell>
          <TableCell align="left" className="rowcellcolor rowTableCell myTableRow">
            {row.icd_10_code_description ? row.icd_10_code_description : 'NA'}
          </TableCell>
          <TableCell align="left" className="rowcellcolor rowTableCell myTableRow">
            {row.name_initials ? row.name_initials : 'NA'}
          </TableCell>
        </TableRow>
      </ThemeProvider>
    </React.Fragment>
  )
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
})

const myrowsjsondata = [
  {
    member_id: '600016',
    bill_key: '20116',
    first_name: 'CHARLES',
    last_name: 'CASH',
    date_paid: 'None',
    date_received: '2020-04-20',
    status: 'IN REVIEW',
    charged_amount: '0.00',
    paid_amount: '0.00',
    paid_provider_name: 'Primary respiratory Tiberculosis'
  },
  {
    member_id: '600017',
    bill_key: '20467',
    first_name: 'AHARLES',
    last_name: 'BASH2',
    date_paid: '2020-05-20',
    date_received: '2020-04-21',
    status: 'FINAL',
    charged_amount: '100.00',
    paid_amount: '10.00',
    paid_provider_name: 'Theracic region'
  },
  {
    member_id: '600018',
    bill_key: '20118',
    first_name: 'MHARLES',
    last_name: 'KASH',
    date_paid: 'None',
    date_received: '2020-04-22',
    status: 'FINAL',
    charged_amount: '200.00',
    paid_amount: '20.00',
    paid_provider_name: 'Tracmatic spondylopathy'
  }
]

function MyVerticallyCenteredModal(props) {
  const { data } = props
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">My Needs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{data.paid_provider_name}</h4>
        <p>{data.member_id}</p>
        <p>{data.charged_amount}</p>
        <p>{data.status}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}
