export const contactNumbersArray = [
  {
    contactNumber: '(888) 366-6243',
    plans: ['1001', '1002', '1003', '1004', '1005', '1006', '1017', '1018', '1019', '1020', '1021', '1022']
  },
  {
    contactNumber: '(855) 229-0257',
    plans: ['6001', '6002', '6003', '6004', '6005', '6006', '6017', '6018', '6019', '6020', '6021', '6022']
  },
  {
    contactNumber: '(800) 987-1990',
    plans: ['7001', '7002', '7003', '7004', '7005', '7006', '7017', '7018', '7019', '7020', '7021', '7022']
  },
  {
    contactNumber: '(888) 942-4725',
    plans: ['8001', '8002', '8003', '8004', '8005', '8006', '8017', '8018', '8019', '8020', '8021', '8022']
  },
  {
    contactNumber: '(855) 030-4941',
    plans: ['9001', '9002', '9003', '9004', '9005', '9006', '9017', '9018', '9019', '9020', '9021', '9022']
  },
  {
    contactNumber: '(888) 792-4722',
    plans: ['10001', '10002', '10003', '10004', '10005', '10006', '10017', '10018', '10019', '10020', '10021', '10022']
  },
  {
    contactNumber: '(888) 792-4722',
    plans: ['11001', '11002', '11003', '11004', '11005', '11006', '11017', '11018', '11019', '11020', '11021', '11022']
  },
  {
    contactNumber: '(855) 809-0110',
    plans: ['12001', '12002', '12003', '12004', '12005', '12006', '12017', '12018', '12019', '12020', '12021', '12022']
  },
  {
    contactNumber: '(888) 992-4789',
    plans: ['13001', '13002', '13003', '13004', '13005', '13006', '13017', '13018', '13019', '13020', '13021', '13022']
  },
  { contactNumber: '(888) 366-6243', plans: ['1011'] },
  { contactNumber: '(888) 791-4722', plans: ['6011'] },
  { contactNumber: '(800) 987-1990', plans: ['7011'] },
  { contactNumber: '(888) 942-4725', plans: ['8011'] },
  { contactNumber: '(855) 030-4941', plans: ['9011'] },
  { contactNumber: '(888) 792-4722', plans: ['10011'] },
  { contactNumber: '(888) 792-4722', plans: ['11011'] },
  { contactNumber: '(855) 809-0110', plans: ['12011'] },
  { contactNumber: '(888) 992-4789', plans: ['13011'] }
]
